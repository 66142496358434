var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.survey)?_c('div',{staticClass:"container d-flex justify-content-center align-items-center",attrs:{"id":"app"}},[_c('validation-observer',{ref:"validationRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-card',{staticClass:"custom-card mb-2"},[_c('b-card-body',[_c('h2',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.survey.name)+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.survey.description)}}),(!_vm.isUpcoming)?_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('MESSAGE.SURVEY_NOTIFICATION'))+" "+_vm._s(_vm.formatDate(_vm.survey.start_date))+" ")]):_vm._e()])],1),_vm._l((_vm.survey.survey_questions),function(surveyQuestion,index){return _c('b-card',{key:index,staticClass:"custom-card mb-2"},[_c('b-card-body',[(_vm.isLoading)?_c('div',{staticClass:"loader-container"},[_c('div',{staticClass:"loader"})]):_vm._e(),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(surveyQuestion.question.statement)}})]},proxy:true}],null,true)},[(surveyQuestion.question.question_type === _vm.questionType[1])?[(surveyQuestion.is_mandatory === 1)?_c('validation-provider',{attrs:{"name":'Question ' + (index + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"maxlength":"200","placeholder":surveyQuestion.question.question_variety === _vm.questionVariety[1] ? _vm.$t('EMAIL_ADDRESS') : _vm.$t('ANSWER')},model:{value:(_vm.survey.survey_questions[index].answer),callback:function ($$v) {_vm.$set(_vm.survey.survey_questions[index], "answer", $$v)},expression:"survey.survey_questions[index].answer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):[_c('b-form-input',{attrs:{"maxlength":"200","placeholder":surveyQuestion.question.question_variety === _vm.questionVariety[1] ? _vm.$t('EMAIL_ADDRESS') : _vm.$t('ANSWER')},model:{value:(_vm.survey.survey_questions[index].answer),callback:function ($$v) {_vm.$set(_vm.survey.survey_questions[index], "answer", $$v)},expression:"survey.survey_questions[index].answer"}})]]:_vm._e(),(
                surveyQuestion.question.question_type === _vm.questionType[2] &&
                  surveyQuestion.question.element === _vm.elementType[0]
              )?[(surveyQuestion.is_mandatory === 1)?_c('validation-provider',{attrs:{"name":'Question ' + (index + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-radio-group',_vm._l((_vm.sortedQuestionAnswers(surveyQuestion.question.question_answers)),function(option,optionIndex){return _c('b-form-radio',{key:optionIndex,attrs:{"value":option},model:{value:(_vm.survey.survey_questions[index].answer),callback:function ($$v) {_vm.$set(_vm.survey.survey_questions[index], "answer", $$v)},expression:"survey.survey_questions[index].answer"}},[_vm._v(" "+_vm._s(option.answer)+" ")])}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):[_c('b-form-radio-group',_vm._l((_vm.sortedQuestionAnswers(surveyQuestion.question.question_answers)),function(option,optionIndex){return _c('b-form-radio',{key:optionIndex,attrs:{"value":option},model:{value:(_vm.survey.survey_questions[index].answer),callback:function ($$v) {_vm.$set(_vm.survey.survey_questions[index], "answer", $$v)},expression:"survey.survey_questions[index].answer"}},[_vm._v(" "+_vm._s(option.answer)+" ")])}),1)]]:_vm._e(),(
                surveyQuestion.question.question_type === _vm.questionType[2] &&
                  false
              )?[(surveyQuestion.is_mandatory === 1)?_c('validation-provider',{attrs:{"name":'Question ' + (index + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-checkbox-group',_vm._l((surveyQuestion.question
                      .question_answers),function(option,optionIndex){return _c('b-form-checkbox',{key:optionIndex,attrs:{"value":option},model:{value:(_vm.survey.survey_questions[index].answer),callback:function ($$v) {_vm.$set(_vm.survey.survey_questions[index], "answer", $$v)},expression:"survey.survey_questions[index].answer"}},[_vm._v(" "+_vm._s(option.answer)+" ")])}),1),_c('small',{staticClass:"text-danger mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):[_c('b-form-checkbox-group',_vm._l((surveyQuestion.question
                      .question_answers),function(option,optionIndex){return _c('b-form-checkbox',{key:optionIndex,attrs:{"value":option},model:{value:(_vm.survey.survey_questions[index].answer),callback:function ($$v) {_vm.$set(_vm.survey.survey_questions[index], "answer", $$v)},expression:"survey.survey_questions[index].answer"}},[_vm._v(" "+_vm._s(option.answer)+" ")])}),1)]]:_vm._e(),(surveyQuestion.question.question_type === _vm.questionType[0])?[(surveyQuestion.is_mandatory === 1)?_c('validation-provider',{attrs:{"rules":"required","name":'Question ' + (index + 1)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-rating',{attrs:{"inline":"","no-border":"","show-value":"","stars":surveyQuestion.question.maximum_value,"variant":"primary"},model:{value:(_vm.survey.survey_questions[index].answer),callback:function ($$v) {_vm.$set(_vm.survey.survey_questions[index], "answer", $$v)},expression:"survey.survey_questions[index].answer"}}),_c('small',{staticClass:"text-danger mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):[_c('b-form-rating',{attrs:{"inline":"","no-border":"","show-value":"","stars":surveyQuestion.question.maximum_value,"variant":"primary"},model:{value:(_vm.survey.survey_questions[index].answer),callback:function ($$v) {_vm.$set(_vm.survey.survey_questions[index], "answer", $$v)},expression:"survey.survey_questions[index].answer"}})]]:_vm._e()],2)],1)],1)}),((!_vm.isSubmit) && _vm.userKey!==0)?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isUpcoming),expression:"isUpcoming"}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("BUTTON.SUBMIT"))+" ")]):_vm._e(),_c('app-footer',{staticClass:"mt-1"})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }