<template>
  <div
    v-if="survey"
    id="app"
    class="container d-flex justify-content-center align-items-center"
  >
    <validation-observer ref="validationRules">
      <b-form @submit.prevent="onSubmit">
        <b-card class="custom-card mb-2">
          <b-card-body>
            <h2 class="mb-2">
              {{ survey.name }}
            </h2>
            <p v-html="survey.description" />
            <h4
              v-if="!isUpcoming"
              class="mb-2"
            >
              {{ $t('MESSAGE.SURVEY_NOTIFICATION') }} {{ formatDate(survey.start_date) }}
            </h4>
          </b-card-body>
        </b-card>
        <b-card
          v-for="(surveyQuestion, index) in survey.survey_questions"
          :key="index"
          class="custom-card mb-2"
        >
          <b-card-body>
            <div
              v-if="isLoading"
              class="loader-container"
            >
              <div class="loader" />
            </div>
            <b-form-group>
              <template v-slot:label>
                <span v-html="surveyQuestion.question.statement" />
              </template>
              <template
                v-if="surveyQuestion.question.question_type === questionType[1]"
              >
                <validation-provider
                  v-if="surveyQuestion.is_mandatory === 1"
                  #default="{ errors }"
                  :name="'Question ' + (index + 1)"
                  rules="required"
                >
                  <b-form-input
                    v-model="survey.survey_questions[index].answer"
                    maxlength="200"
                    :placeholder="surveyQuestion.question.question_variety === questionVariety[1] ? $t('EMAIL_ADDRESS') : $t('ANSWER')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <template v-else>
                  <b-form-input
                    v-model="survey.survey_questions[index].answer"
                    maxlength="200"
                    :placeholder="surveyQuestion.question.question_variety === questionVariety[1] ? $t('EMAIL_ADDRESS') : $t('ANSWER')"
                  />
                </template>
              </template>

              <template
                v-if="
                  surveyQuestion.question.question_type === questionType[2] &&
                    surveyQuestion.question.element === elementType[0]
                "
              >
                <validation-provider
                  v-if="surveyQuestion.is_mandatory === 1"
                  #default="{ errors }"
                  :name="'Question ' + (index + 1)"
                  rules="required"
                >
                  <b-form-radio-group>
                    <b-form-radio
                      v-for="(option, optionIndex) in sortedQuestionAnswers(surveyQuestion.question.question_answers)"
                      :key="optionIndex"
                      v-model="survey.survey_questions[index].answer"
                      :value="option"
                    >
                      {{ option.answer }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <template v-else>
                  <b-form-radio-group>
                    <b-form-radio
                      v-for="(option, optionIndex) in sortedQuestionAnswers(surveyQuestion.question.question_answers)"
                      :key="optionIndex"
                      v-model="survey.survey_questions[index].answer"
                      :value="option"
                    >
                      {{ option.answer }}
                    </b-form-radio>
                  </b-form-radio-group>
                </template>
              </template>

              <template
                v-if="
                  surveyQuestion.question.question_type === questionType[2] &&
                    false
                "
              >
                <validation-provider
                  v-if="surveyQuestion.is_mandatory === 1"
                  #default="{ errors }"
                  :name="'Question ' + (index + 1)"
                  rules="required"
                >
                  <b-form-checkbox-group>
                    <b-form-checkbox
                      v-for="(option, optionIndex) in surveyQuestion.question
                        .question_answers"
                      :key="optionIndex"
                      v-model="survey.survey_questions[index].answer"
                      :value="option"
                    >
                      {{ option.answer }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <small class="text-danger mt-1">{{ errors[0] }}</small>
                </validation-provider>
                <template v-else>
                  <b-form-checkbox-group>
                    <b-form-checkbox
                      v-for="(option, optionIndex) in surveyQuestion.question
                        .question_answers"
                      :key="optionIndex"
                      v-model="survey.survey_questions[index].answer"
                      :value="option"
                    >
                      {{ option.answer }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </template>
              </template>
              <template
                v-if="surveyQuestion.question.question_type === questionType[0]"
              >
                <validation-provider
                  v-if="surveyQuestion.is_mandatory === 1"
                  #default="{ errors }"
                  rules="required"
                  :name="'Question ' + (index + 1)"
                >
                  <b-form-rating
                    v-model="survey.survey_questions[index].answer"
                    inline
                    no-border
                    show-value
                    :stars="surveyQuestion.question.maximum_value"
                    variant="primary"
                  />
                  <small
                    class="text-danger mt-1"
                  >{{ errors[0] }}</small>
                </validation-provider>
                <template v-else>
                  <b-form-rating
                    v-model="survey.survey_questions[index].answer"
                    inline
                    no-border
                    show-value
                    :stars="surveyQuestion.question.maximum_value"
                    variant="primary"
                  />
                </template>
              </template>
            </b-form-group>
          </b-card-body>
        </b-card>
        <b-button
          v-if="(!isSubmit) && userKey!==0"
          v-show="isUpcoming"
          type="submit"
          variant="primary"
        >
          {{ $t("BUTTON.SUBMIT") }}
        </b-button>
        <app-footer class="mt-1" />
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRating,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import constants from '@/constants/static.json'
import mixinAlert from '@/constants/mixinAlert'
import useJwt from '@/auth/jwt/useJwt'
import { isBeforeToday, isDateUpToToday } from '@/constants/utils'
import { mixinDate } from '@/constants/mixinDate'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRating,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    AppFooter,
  },
  mixins: [mixinDate],
  data() {
    return {
      survey: {
        ID: 0,
        set_id: 0,
        name: '',
        start_date: '',
        end_date: '',
        language: '',
        total_users: 0,
        total_responses: 0,
        average_rating: 0,
        survey_status: '',
        response_id: 0,
        is_responded: false,
        company_id: 0,
        category_id: 0,
        description: '',
        survey_questions: [],
      },
      ipAddress: 0,
      user_id: 0,
      userKey: 0,
      surveyID: 0,
      required,
      isSubmit: false,
      isLoading: false,
      isUpcoming: false,
      questionType: constants.QUESTION_TYPE,
      elementType: constants.ELEMENT_TYPE,
      questionVariety: constants.QUESTION_VARIETY,
      encryptedDefaultId: constants.ENCRYPTED_DEFAULT_ID,

    }
  },
  mounted() {
    this.$i18n.locale = constants.LOCALE.SWEDISH
    this.fetchIpAddress()
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
    }
  },
  created() {
    const { id } = this.$route.params
    const { userKey } = this.$route.query
    const previousId = useJwt.getLocalStorage(id)
    const previousUserKey = useJwt.getLocalStorage(userKey)

    if (id === previousId && userKey === previousUserKey && userKey === this.encryptedDefaultId) {
      this.$router.push({ name: 'survey-complete' })
      return // Stop further execution
    }

    if (id) {
      this.surveyID = id
      if (userKey) {
        this.userKey = userKey
      }
      // eslint-disable-next-line radix
      if (this.surveyID !== '') {
        const payload = {
          id: this.surveyID,
          query: `user_id=${this.userKey || ''}`,
        }
        this.fetchSurveyByID(payload).then(async response => {
          if (response) {
            this.survey = response.data
            this.$i18n.locale = this.survey.language
            if ((!this.survey.is_responded) && isBeforeToday(this.survey.end_date)) {
              this.user_id = this.survey.survey_users[0].user_id
              this.isUpcoming = isDateUpToToday(this.survey.start_date)
            } else if (this.userKey) {
              this.$router.push({
                name: 'survey-results-id',
                params: {
                  id: this.surveyID,
                },
                query: {
                  userKey: this.userKey,
                },
              })
            }
          }
        })
      }
    }
  },
  methods: {
    ...mapActions('onboarding', ['fetchSurveyByID', 'createSurvey']),
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          const data = this.recreateList()
          this.isSubmit = true
          this.createSurvey(data)
            .then(response => {
              if (response) {
                this.isLoading = false
                useJwt.setLocalStorage(this.surveyID, this.surveyID)
                useJwt.setLocalStorage(response.data.user_id, response.data.user_id)
                this.$router.push({
                  name: 'survey-results-id',
                  params: {
                    id: this.surveyID,
                  },
                  query: {
                    userKey: response.data.user_id,
                    responseKey: response.data.response_id,
                  },
                })
              }
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    sortedQuestionAnswers(questionAnswers) {
      return questionAnswers.slice().sort((a, b) => a.sequence - b.sequence)
    },
    fetchIpAddress() {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          this.ipAddress = data.ip
        })
        .catch(() => {
          this.ipAddress = '0.0.0.0'
        })
    },
    recreateList() {
      return this.survey.survey_questions.map(item => {
        let answerValue
        if (Array.isArray(item.answer)) {
          answerValue = item.answer.map(answer => answer.answer).join(' | ')
        } else if (typeof item.answer === 'object') {
          answerValue = item.answer.answer
        } else {
          answerValue = item.answer
        }

        return {
          survey_id: item.survey_id,
          set_id: this.survey.set_id,
          user_id: this.user_id,
          company_id: this.survey.company_id,
          question_id: item.question.questionID,
          answer: answerValue,
          question_type: item.question.question_type,
          maximum_value: item.question.maximum_value,
          minimum_value: item.question.minimum_value,
          created_by: this.ipAddress,
        }
      })
    },
  },
}
</script>

<style scoped>
#app {
  font-family: "Roboto", sans-serif;
  background-color: #f4f4f4;
  min-height: 85vh;
  display: flex;
  align-items: center;
}

h2 {
  color: #ff6400;
}

h4 {
  color: #28c76f;
}

b-form-group {
  max-width: 500px;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #ff6400;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.custom-card {
    border: 2px solid #ff6400; /* Border color */
    border-radius: 10px; /* Border radius */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
